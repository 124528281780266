import { JSONABLE } from "ts-extend";
import { apiRequest, getTicket } from "..";
import { APIExample } from "../definition";
export type ViewAPIs = APIExample<{
    "/order/view": {
        method: "POST"
        toServer: {
            ticket: string,
            projectId: number,
        },
        toClient: Data
    },
}>
export const api_viewer = async (projectId: number) => {
    return await apiRequest("/order/view", {
        ticket: getTicket(),
        projectId,
    })
}

export type Project = {
    id: number;
    name: string;
    createTime: string | Date;
    removed: boolean;
}

export type Activity = {
    id: number;
    name: string;
    published: boolean;
    removed: boolean;
    createTime: string | Date;
    projectId: number;
    Project: Project;
}

export type Schedule = {
    id: number;
    published: boolean;
    startTime: string | Date;
    endTime: string | Date;
    regEndTime: string | Date;
    removed: boolean;
    createTime: string | Date;
    activityId: number;
    Activity: Activity;
}

export type Seat = {
    id: number;
    removed: boolean;
    createTime: string | Date;
    scheduleId: number;
    Schedule: Schedule;
}

export type Db = {
    id: number;
    rawData: string;
    userGUID: string;
    name: string;
    phoneNumber: string;
    email: string;
    channel: string;
    cancelled: boolean;
    removed: boolean;
    createTime: string | Date;
    seatId: number;
    Seat: Seat;
}

export type Data = {
    db: Db[];
}



import { api_viewer } from '@/api/children/viewer';
import { User } from '@/user';
import { Drawer, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import React, { PureComponent } from 'react';
import { Orders } from '../Order';
import { Seats } from '../Seat';



type ActivitysProps = {
    projectId: number
    user: User
}
type ActivitysState = {
    manageingSeatId?: number
    manageingScheduleId?: number
    columns?: ColumnsType<DataType>
    items?: DataType[]
    manageingActivityId?: number
}
interface DataType {
    createTime: Date
    id: number
    seatId: number
    scheduleId: number
    key: React.Key
    name: string
    email: string
    channel: string
    phone: string
    activityName: string
    scheduleStartTime: Date
}


export class ViewActivitys extends PureComponent<ActivitysProps, ActivitysState> {
    constructor(props: ActivitysProps) {
        super(props)
        this.state = {
            // columns: this.columns
        }
    }
    render() {
        return <div>
            <Table
                columns={this.state.columns}
                dataSource={this.state.items}
                pagination={{ showSizeChanger: true }}
            />
            <Drawer
                title="预定管理"
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={() => { this.setState({ manageingSeatId: undefined }); this.fetchData() }}
                open={typeof this.state.manageingSeatId === "number"}>
                {this.state.manageingSeatId ? <Orders seatId={this.state.manageingSeatId} user={this.props.user} /> : ""}
            </Drawer>
            <Drawer
                title={`座位管理`}
                placement="right"
                closable
                destroyOnClose
                width={"80%"}
                onClose={() => { this.setState({ manageingScheduleId: undefined }); this.fetchData() }}
                open={typeof this.state.manageingScheduleId === "number"}>
                {this.state.manageingScheduleId ? <Seats
                    projectId={this.props.projectId}
                    scheduleId={this.state.manageingScheduleId}
                    user={this.props.user} /> : ""}
            </Drawer>


        </div>

    }
    async componentDidMount() {
        this.fetchData()
    }
    getColumns = (actions: Set<string>, times: Set<string>) => {

        const actionsfilters: { text: string, value: string }[] = Array.from(actions, (item) => ({ text: item, value: item }))
        const timesfilters: { text: string, value: string }[] = Array.from(times, (item) => ({ text: new Date(item).toLocaleString(), value: item }))
        const columns: ColumnsType<DataType> = [
            {
                title: 'ID',
                dataIndex: 'key',
                key: 'key',
            },
            {
                title: '活动',
                dataIndex: 'activityName',
                key: 'activityName',
                filters: actionsfilters,
                onFilter: (value, record) => record.activityName === value,
            },
            {
                title: '提交时间',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (t, item) => item.createTime.toLocaleString(),
                sorter: (a, b) => a.createTime.valueOf() - b.createTime.valueOf(),
                sortDirections: ["ascend", "descend"],
                defaultSortOrder: "descend",
            },
            {
                title: '开始时间',
                dataIndex: 'scheduleStartTime',
                key: 'scheduleStartTime',
                render: (t, item) => item.scheduleStartTime.toLocaleString(),
                filters: timesfilters,
                onFilter: (value, record) => record.scheduleStartTime.valueOf() === new Date(String(value)).valueOf(),
                sorter: (a, b) => a.scheduleStartTime.valueOf() - b.scheduleStartTime.valueOf(),
                sortDirections: ["ascend", "descend"],
                defaultSortOrder: "descend",
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: '电话',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '渠道',
                dataIndex: 'channel',
                key: 'channel',
                render: (t, item) => item.channel || "(未知)",
            },
            {
                title: '管理',
                key: 'action',
                fixed: "right",
                width: 150,
                render: (_, item) => (
                    <Space size="middle">
                        <a onClick={() => this.setState({ manageingSeatId: item.seatId })}>{"座位"}</a>
                        <a onClick={() => this.setState({ manageingScheduleId: item.scheduleId })}>{"场次"}</a>
                    </Space>
                ),
            },
        ];
        return columns
    }
    async fetchData() {
        const debug = localStorage.debugMode === "true"
        const data = await api_viewer(this.props.projectId)

        const items: DataType[] = []
        const actions: Set<string> = new Set()
        const times: Set<string> = new Set()


        for (let order of data.db) {
            if (!debug && (order.removed
                || order.Seat.removed
                || order.Seat.Schedule.removed
                || order.Seat.Schedule.Activity.removed
                || order.Seat.Schedule.Activity.Project.removed
            )) {
                continue
            }

            items.push({
                id: order.id,
                key: order.id,
                seatId: order.Seat.id,
                scheduleId: order.Seat.Schedule.id,
                name: order.name,
                email: order.email,
                channel: order.channel,
                phone: order.phoneNumber,
                activityName: order.Seat.Schedule.Activity.name,
                scheduleStartTime: new Date(order.Seat.Schedule.startTime),
                createTime: new Date(order.createTime),
            })
            actions.add(order.Seat.Schedule.Activity.name)
            times.add(new Date(order.Seat.Schedule.startTime).toISOString())
        }
        const columns = this.getColumns(actions, times)
        console.log("items", items)

        items.sort((a, b) => { return b.id - a.id })
        this.setState({
            items,
            columns,
        })
    }


}

